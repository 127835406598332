<template>
  <div>
    <div class="Box">
      <p>添加代理</p>
     <el-input v-model="agency" placeholder="请输入内容" style="width:200px"></el-input>
     <el-button type="primary" class="btn" @click="handleClick">添加</el-button>
    </div>
  </div>
</template>
<script>
import { agentAdd } from '@/api/adminApi.js'
export default {
  data () {
    return {
      agency: ''
    }
  },
  mounted () {
  },
  methods: {
    handleClick () { // 添加代理
      const data = { account: this.agency }
      if (this.agency == '') {
        this.$message('代理账号不能为空')
      } else {
        agentAdd(data).then(res => {
          if (res.status.code == 1) {
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.agency = ''// 清空输入框
          } else {
            this.$message.error(res.status.msg)
          }
        }).catch(err => { console.log(err) })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .Box{
    width: 500px;
    .btn{
      margin-left: 6px;
    }
  }
</style>
